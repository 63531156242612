
import Detect from 'mobile-detect';

import initialState from './initialState';
import Storage from '../helpers/storage';
import * as types from '../constants/types';
import * as betas from '../constants/betas';
import Event3rdParty from '../helpers/event3rdParty';

const objectAssign = require('object-assign');

const session = JSON.parse(JSON.stringify(initialState.session));
session.token = Storage.getItem('token') || null;
session.isB2C = Storage.getItem('isUseB2C') || null;
session.capabilities = Storage.getItem('capabilities') || [];

if (session.token && (session.isB2C === null || session.isB2C === 'false')) {
  const payload = JSON.parse(atob(session.token.split('.')[1]));
  session.userid = payload.userid;
  session.capabilities = payload.capabilities ?? [];
}

const devAccessOriginatorIds = [4846];
const userHasDevAccess = (originatorId) => devAccessOriginatorIds.includes(originatorId);

export default function sessionReducer(state = session, action) {
  switch (action.type) {
    case types.GET_DETAILS: {
      const details = action.data;
      const names = details.contact.split(' ');

      details.initials = details.contact.split(' ').map((name) => name.substr(0, 1)).join('');
      details.firstName = names[0];
      details.lastName = names[1] || null;

      details.isDev = action.data.isDev || userHasDevAccess(action.data.introducerId);
      // LNS originator shouldn't use IQ to submit Motor Consumer/Caravan Loan, hence filtering out Motor Consumer/Caravan products for LNS originator
      details.products = details.type == types.OriginatorTypes.LNS 
        ? details.products.filter((product) => product.productGroupId !== types.PRODUCTGROUPS.MOTOR_CONSUMER && product.productGroupId !== types.PRODUCTGROUPS.MOTOR_COSUMER_CARAVAN) 
        : details.products;

      details.isResidential = details.products && !!details.products.filter((product) => [types.PRODUCTGROUPS.AUSTRALIA_PROPERTY_MORTGAGE, types.PRODUCTGROUPS.LIBERTY_FREE_AUSTRALIA, types.PRODUCTGROUPS.LIBERTY_SHARP_AUSTRALIA].indexOf(product.productGroupId) != -1).length;

      details.isMotor = details.products && !!details.products.filter((product) => [types.PRODUCTGROUPS.MOTOR_CONSUMER, types.PRODUCTGROUPS.MOTOR_COMMERCIAL, types.PRODUCTGROUPS.MOTOR_COSUMER_CARAVAN, types.PRODUCTGROUPS.MOTOR_NOVATED_LEASE].indexOf(product.productGroupId) != -1).length;

      details.isMotorConsumer = details.products && !!details.products.filter((product) => [types.PRODUCTGROUPS.MOTOR_CONSUMER].indexOf(product.productGroupId) != -1).length;

      details.isMotorCommercial = details.products && !!details.products.filter((product) => [types.PRODUCTGROUPS.MOTOR_COMMERCIAL].indexOf(product.productGroupId) != -1).length;

      details.isMotorCaravan = details.products && !!details.products.filter((product) => [types.PRODUCTGROUPS.MOTOR_COSUMER_CARAVAN].indexOf(product.productGroupId) != -1).length;

      details.isMotorNovatedLease = details.products && !!details.products.filter((product) => [types.PRODUCTGROUPS.MOTOR_NOVATED_LEASE].indexOf(product.productGroupId) != -1).length;

      details.isSubmission = details.introducerId && betas.RESI_SUBMISSION.some((userId) => userId == details.introducerId);

      details.isIDVerification = details.introducerId && state.capabilities.indexOf(types.CAPABILITIES.ID_Verification) !== -1;

      details.isGreenUser = details.introducerId && state.capabilities.indexOf(types.CAPABILITIES.GREEN_VEHICLE) !== -1 && details.isMotorNovatedLease === false;

      details.isPhoneSized = new Detect(window.navigator.userAgent).isPhoneSized();

      details.isLfiProtectUser = (details.introducerId && state.capabilities.indexOf(types.CAPABILITIES.LFI_Pre_Deferral) !== -1);
      
      details.isUAFUser = (details.introducerId && state.capabilities.indexOf(types.CAPABILITIES.UAF) !== -1);
      
      const newSession = objectAssign({}, state, { details });

      Event3rdParty.identify(details.username);

      return newSession;
    }

    case types.GET_MIGRATED: {
      const newSession = objectAssign({}, state, { isB2CMigrated: action.data });
      return newSession;
    }

    case types.SET_DETAIL_NUMBERS: {
      const details = objectAssign({}, state.details, action.data);
      const newSession = objectAssign({}, state, { details });
      return newSession;
    }

    case types.SET_DETAIL_EMAILS: {
      const details = objectAssign({}, state.details, action.data);
      const newSession = objectAssign({}, state, { details });
      return newSession;
    }

    case types.SET_DETAIL_ADDRESS: {
      const details = objectAssign({}, state.details, { address: action.data });
      const newSession = objectAssign({}, state, { details });
      return newSession;
    }

    case types.SET_DETAIL_PASSWORD: {
      const details = objectAssign({}, state.details, action.data);
      const newSession = objectAssign({}, state, { details });
      return newSession;
    }

    case types.SET_DETAIL_SUBSCRIPTION: {
      const details = objectAssign({}, state.details, action.data);
      const newSession = objectAssign({}, state, { details });
      return newSession;
    }

    case types.DO_LOGIN: {
      const payload = JSON.parse(atob(action.data.token.split('.')[1]));

      const newState = {
        token: action.data.token,
        username: action.data.username,
        userid: payload.userid,
        capabilities: payload.capabilities,
      };
      const newSession = { ...state, ...newState };

      Storage.setItem('token', JSON.stringify(action.data.token));
      Storage.setItem('session', payload.userid);
      Storage.setItem('username', action.data.username);
      Storage.setItem('capabilities', payload.capabilities);

      return newSession;
    }

    case types.RENEW_TOKEN_IN_PROGRESS: {
      const newSession = objectAssign({}, state, { renewPromise: action.renewPromise });

      return newSession;
    }

    case types.RENEW_TOKEN_FAILURE: {
      const newSession = objectAssign({}, state, { renewPromise: null, failed: state.failed + 1 });

      return newSession;
    }


    case types.RENEW_TOKEN_SUCCESS: {
      const newState = {
        token: action.data.token, renewPromise: null, timer: 0, failed: 0,
      };
      const newSession = objectAssign({}, state, newState);

      Storage.setItem('token', JSON.stringify(action.data.token));

      return newSession;
    }

    case types.TIMER_START: {
      const newSession = objectAssign({}, state, { timer: 0 });

      return newSession;
    }

    case types.TIMER_TICK: {
      const newSession = objectAssign({}, state, { timer: state.timer + 10 });

      return newSession;
    }

    default:
      return state;
  }
}
