import * as React from 'react';
import * as ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'react-bootstrap';
import { Input, Select } from 'formsy-react-components';
import Mask from '../../helpers/mask';
import Formsy from 'formsy-react';
import * as referenceActions from '../../actions/referenceActions';
import ReferenceApi from '../../api/referenceApi';
import { MINIMUM_MOTOR_NOVATED_RESIDUAL_PERCENTAGE, MAXIMUM_MOTOR_NOVATED_BROKERAGE_PERCENTAGE, PRODUCTGROUPS } from '../../constants/types';

type NovatedLeaseProps ={
  application: any,
  reference: any,
  formsy: Formsy | null,
}

type NovatedLeaseState ={
  minimumResidualPercentage: number,
  residualPercentage: number,
  brokeragePercentage: number,
  maxClaimableGstAmount: number,
  residualPercentageError: string,
  residualPercentageValidation: number,
  brokeragePercentageValidation: number
}

export class ApplicationUpdateProductNovatedLease extends React.Component<NovatedLeaseProps, NovatedLeaseState> {
  brokeragePercentageRef = React.createRef<HTMLInputElement>();
  residualPercentageRef = React.createRef<HTMLInputElement>();

  constructor(props: NovatedLeaseProps) {
    super(props);

    const minimumResidualPercentage = MINIMUM_MOTOR_NOVATED_RESIDUAL_PERCENTAGE[props.application.motorProduct.loanTermMonths];

    this.brokeragePercentageRef = React.createRef<HTMLInputElement>();
    this.residualPercentageRef = React.createRef<HTMLInputElement>();

    this.state = {
      minimumResidualPercentage,
      maxClaimableGstAmount: 0,
      residualPercentage: 0,
      brokeragePercentage: 0,
      residualPercentageError: '',
      residualPercentageValidation: 0,
      brokeragePercentageValidation: 0
    }

    this.getMaxClaimableGst = this.getMaxClaimableGst.bind(this);
    this.getFormsyValue = this.getFormsyValue.bind(this);
    this.setFormsyValue = this.setFormsyValue.bind(this);
    this.setVehiclePrice = this.setVehiclePrice.bind(this);
    this.setAccessories = this.setAccessories.bind(this);
    this.setLuxuryTax = this.setLuxuryTax.bind(this);
    this.setOnRoadCosts = this.setOnRoadCosts.bind(this);
    this.setBrokeragePercentage = this.setBrokeragePercentage.bind(this);
    this.setResidualPercentage = this.setResidualPercentage.bind(this);
    this.setResidualValue = this.setResidualValue.bind(this);

    const residualPercentage = this.initializeResidualPercentage(props.application);
    const brokeragePercentage = this.initializeBrokeragePercentage(props.application);  
   
    this.state = {
      ...this.state,
      residualPercentage,
      brokeragePercentage,
      residualPercentageValidation : residualPercentage,
      brokeragePercentageValidation : brokeragePercentage
    }
  }

  componentDidMount() {
    this.getMaxClaimableGst(PRODUCTGROUPS.MOTOR_NOVATED_LEASE);
  }

  setMaxClaimableGstAmount(maxClaimableGstAmount: number) {
    this.setState({ maxClaimableGstAmount });
  }

  setMinimumResidualPercentage(e: number) {
    const minimumResidualPercentage = MINIMUM_MOTOR_NOVATED_RESIDUAL_PERCENTAGE[e];
    this.setState({ minimumResidualPercentage});
  }

  setGstValues(maxClaimableGstAmount: number) {
    const vehiclePrice = this.getFormsyValue('motorPurpose.vehiclePrice') || 0;
    const accessories = this.getFormsyValue('motorPurpose.accessories') || 0;
    const claimableGST = this.calculateClaimableGst(accessories, vehiclePrice, maxClaimableGstAmount);
    const nonClaimableGST = this.calculateNonClaimableGst(accessories, vehiclePrice, claimableGST);

    let residualPercentage = this.state.residualPercentage || 0; 
    
    const residualValue = this.calculateResidualValue(residualPercentage, vehiclePrice, claimableGST);
    residualPercentage = this.calculateResidualPercentage(this.state.minimumResidualPercentage, residualValue, vehiclePrice, claimableGST);

    this.setFormsyValue('motorPurpose.claimableGST', claimableGST);
    this.setFormsyValue('motorPurpose.nonClaimableGST', nonClaimableGST);
    this.setFormsyValue('motorPurpose.residualValue', residualValue);
    
    ReactTooltip.rebuild();
  }

  getMaxClaimableGst(productGroupId) {     
    ReferenceApi.getMaxClaimableGstAmount(productGroupId)
    .then((response) => {
      let maxClaimableGstAmount = response.data;
      this.setMaxClaimableGstAmount(maxClaimableGstAmount);
      this.setGstValues(maxClaimableGstAmount);
    });
  }

  setVehiclePrice(e) {
    const vehiclePrice = parseFloat(e.target.value) || 0;
    const accessories = parseFloat(this.getFormsyValue('motorPurpose.accessories')) || 0;
    const onRoadCosts = parseFloat(this.getFormsyValue('motorPurpose.onRoadCost')) || 0;
    const luxuryTax = parseFloat(this.getFormsyValue('motorPurpose.luxuryCarTax')) || 0;
    const brokeragePercentage =  this.state.brokeragePercentage || 0;
    const claimableGST = this.calculateClaimableGst(accessories, vehiclePrice, this.state.maxClaimableGstAmount);
    const nonClaimableGST = this.calculateNonClaimableGst(accessories, vehiclePrice, claimableGST);
    const maximumBrokeragePercentage = MAXIMUM_MOTOR_NOVATED_BROKERAGE_PERCENTAGE * 100;

    let residualPercentage = this.state.residualPercentage || 0;
    
    const residualValue = this.calculateResidualValue(residualPercentage, vehiclePrice, claimableGST);

    this.setFormsyValue('motorPurpose.claimableGST', claimableGST);
    this.setFormsyValue('motorPurpose.nonClaimableGST', nonClaimableGST);
    this.setFormsyValue('motorPurpose.residualValue', residualValue);

    let brokerageValue =
        this.calculateBrokerageValue(
          maximumBrokeragePercentage, 
          brokeragePercentage, 
          vehiclePrice, 
          accessories, 
          onRoadCosts, 
          luxuryTax, 
          claimableGST
        )
    this.setFormsyValue('motorPurpose.applicationFee', brokerageValue);
  }

  setAccessories(e) {
    const accessories = parseFloat(e.target.value) || 0;
    const vehiclePrice = parseFloat(this.getFormsyValue('motorPurpose.vehiclePrice')) || 0;
    const onRoadCosts = parseFloat(this.getFormsyValue('motorPurpose.onRoadCost')) || 0;
    const luxuryTax = parseFloat(this.getFormsyValue('motorPurpose.luxuryCarTax')) || 0;
    const brokeragePercentage = this.state.brokeragePercentage || 0;
    const claimableGST = this.calculateClaimableGst(accessories, vehiclePrice, this.state.maxClaimableGstAmount);
    const nonClaimableGST = this.calculateNonClaimableGst(accessories, vehiclePrice, claimableGST);
    const maximumBrokeragePercentage = MAXIMUM_MOTOR_NOVATED_BROKERAGE_PERCENTAGE * 100;

    let brokerageValue =
        this.calculateBrokerageValue(
          maximumBrokeragePercentage, 
          brokeragePercentage, 
          vehiclePrice, 
          accessories, 
          onRoadCosts, 
          luxuryTax, 
          claimableGST
        )
    this.setFormsyValue('motorPurpose.applicationFee', brokerageValue);
    this.setFormsyValue('motorPurpose.claimableGST', claimableGST);
    this.setFormsyValue('motorPurpose.nonClaimableGST', nonClaimableGST);
  }

  setLuxuryTax(e) {
    const luxuryTax = parseFloat(e.target.value) || 0;
    const vehiclePrice = parseFloat(this.getFormsyValue('motorPurpose.vehiclePrice')) || 0;
    const accessories = parseFloat(this.getFormsyValue('motorPurpose.accessories')) || 0;
    const onRoadCosts = parseFloat(this.getFormsyValue('motorPurpose.onRoadCost')) || 0;
    const brokeragePercentage = this.state.brokeragePercentage || 0;
    const claimableGST = parseFloat(this.getFormsyValue('motorPurpose.claimableGST')) || 0;
    const maximumBrokeragePercentage = MAXIMUM_MOTOR_NOVATED_BROKERAGE_PERCENTAGE * 100;

    let brokerageValue =
        this.calculateBrokerageValue(
          maximumBrokeragePercentage, 
          brokeragePercentage, 
          vehiclePrice, 
          accessories, 
          onRoadCosts, 
          luxuryTax, 
          claimableGST
        )
    this.setFormsyValue('motorPurpose.applicationFee', brokerageValue);
  }

  setOnRoadCosts(e) {
    const onRoadCosts = parseFloat(e.target.value) || 0;
    const vehiclePrice = parseFloat(this.getFormsyValue('motorPurpose.vehiclePrice')) || 0;
    const accessories = parseFloat(this.getFormsyValue('motorPurpose.accessories')) || 0;
    const luxuryTax = parseFloat(this.getFormsyValue('motorPurpose.luxuryCarTax')) || 0;
    const brokeragePercentage = this.state.brokeragePercentage || 0;
    const claimableGST = parseFloat(this.getFormsyValue('motorPurpose.claimableGST')) || 0;
    const maximumBrokeragePercentage = MAXIMUM_MOTOR_NOVATED_BROKERAGE_PERCENTAGE * 100;

    let brokerageValue =
        this.calculateBrokerageValue(
          maximumBrokeragePercentage, 
          brokeragePercentage, 
          vehiclePrice, 
          accessories, 
          onRoadCosts, 
          luxuryTax, 
          claimableGST
        )
    this.setFormsyValue('motorPurpose.applicationFee', brokerageValue);
  }

  setResidualPercentage(e) {
    const residualPercentage = e.target.value || 0;
    const vehiclePrice = this.getFormsyValue('motorPurpose.vehiclePrice') || 0;
    const claimableGST = this.getFormsyValue('motorPurpose.claimableGST') || 0;
    const residualValue = this.calculateResidualValue(residualPercentage, vehiclePrice, claimableGST);

    this.setFormsyValue('motorPurpose.residualValue', residualValue);
    this.setState({
      ...this.state,
      residualPercentageValidation: residualPercentage
    })
    
  }

  setResidualValue(e) {
    const residualValue = e.target.value || 0;
    const vehiclePrice = this.getFormsyValue('motorPurpose.vehiclePrice') || 0;
    const claimableGST = this.getFormsyValue('motorPurpose.claimableGST') || 0;
    const residualPercentage = 
      this.calculateResidualPercentage(
        this.state.minimumResidualPercentage, 
        residualValue, 
        vehiclePrice, 
        claimableGST);

    this.setFormsyValue('motorPurpose.residualValue', residualValue);
    this.setState({
      ...this.state,
      residualPercentageValidation: residualPercentage,
      residualPercentage: residualPercentage
    })
  }

  setBrokeragePercentage(e) {
    
    const brokeragePercentage = parseFloat(e.target.value) || 0;
    const brokeragePercentageRounded = Math.round(brokeragePercentage * 100) / 100;
    const vehiclePrice = parseFloat(this.getFormsyValue('motorPurpose.vehiclePrice')) || 0;
    const accessories = parseFloat(this.getFormsyValue('motorPurpose.accessories')) || 0;
    const onRoadCosts = parseFloat(this.getFormsyValue('motorPurpose.onRoadCost')) || 0;
    const luxuryTax = parseFloat(this.getFormsyValue('motorPurpose.luxuryCarTax')) || 0;
    const claimableGst = parseFloat(this.getFormsyValue('motorPurpose.claimableGST')) || 0;
    const maximumBrokeragePercentage = MAXIMUM_MOTOR_NOVATED_BROKERAGE_PERCENTAGE * 100;

    const brokerageValue = this.calculateBrokerageValue(
      maximumBrokeragePercentage, 
      brokeragePercentageRounded, 
      vehiclePrice, 
      accessories, 
      onRoadCosts, 
      luxuryTax, 
      claimableGst
    )
    let cappedPercent = brokeragePercentageRounded > maximumBrokeragePercentage ? maximumBrokeragePercentage: brokeragePercentageRounded;
    this.setFormsyValue('motorPurpose.applicationFee', brokerageValue);
    this.setState({
      ...this.state,
      brokeragePercentageValidation: brokeragePercentage
    })
  }

  calculateBrokeragePercentage(
    brokerageValue: number, 
    vehiclePrice: number, 
    accessories: number, 
    onRoadCosts: number, 
    luxuryTax: number, 
    claimableGST: number) {
    const totalAssetPrice = vehiclePrice + accessories + onRoadCosts + luxuryTax;
    const percentage = (brokerageValue / (totalAssetPrice - claimableGST)) * 100

    return Math.round(percentage * 100) / 100;
  }

  calculateBrokerageValue(
    maximumBrokeragePercentage: number, 
    brokeragePercentage: number, 
    vehiclePrice: number, 
    accessories: number, 
    onRoadCosts: number, 
    luxuryTax: number, 
    claimableGst: number) {
    const totalAssetPrice = vehiclePrice + accessories + onRoadCosts + luxuryTax;
    let percent = brokeragePercentage >= maximumBrokeragePercentage ? maximumBrokeragePercentage : brokeragePercentage;
    let brokerageAmount = (totalAssetPrice - claimableGst) * (percent / 100.0);
    
    return Math.round(brokerageAmount); // the api rounds this figure so keeping it consistent here
  }

  initializeBrokeragePercentage(application){
    const vehiclePrice = application.motorPurpose.vehiclePrice || 0;
    const accessories = application.motorPurpose.accessories || 0;
    const onRoadCosts = application.motorPurpose.onRoadCost || 0;
    const luxuryTax = application.motorPurpose.luxuryCarTax || 0;
    const brokerageValue = application.motorPurpose.applicationFee || 0;
    const claimableGST = application.motorPurpose.claimableGST || 0;
    const brokeragePercentage = 
      this.calculateBrokeragePercentage( 
        brokerageValue, 
        vehiclePrice, 
        accessories, 
        onRoadCosts, 
        luxuryTax, 
        claimableGST
      );
      
    return brokeragePercentage;
  }

  calculateClaimableGst(accessories, vehiclePrice, maxClaimableGstAmount) {
    let claimableGst = Math.min((parseFloat(accessories) + parseFloat(vehiclePrice)) * 1.0 / 11.0, maxClaimableGstAmount);
    return Math.round(claimableGst * 100) / 100;
  }

  calculateNonClaimableGst(accessories, vehiclePrice, claimableGST) {
    const totalGST = (parseFloat(accessories) + parseFloat(vehiclePrice)) * 1.0 / 11.0;
    let nonClaimableGst = totalGST - claimableGST;
    return Math.round(nonClaimableGst * 100) / 100;
  }

  calculateResidualValue(residualPercentage, vehiclePrice, claimableGST) {
    const residualValue = parseFloat(vehiclePrice) - parseFloat(claimableGST);
    let residualAmount = residualPercentage === undefined || residualPercentage === 0 ? residualValue : residualValue * residualPercentage / 100.0;
    return Math.round(residualAmount * 100) / 100;
  }

  initializeResidualPercentage(application){
    const vehiclePrice = application.motorPurpose.vehiclePrice || 0;
    const claimableGST = application.motorPurpose.claimableGST || 0;
    const residualValue = application.motorPurpose.residualValue || 0;
    const minimumResidualPercentage = MINIMUM_MOTOR_NOVATED_RESIDUAL_PERCENTAGE[application.motorProduct.loanTermMonths];
     
    const residualPercentage = this.calculateResidualPercentage(minimumResidualPercentage, residualValue, vehiclePrice, claimableGST);
    return residualPercentage;
  }
  
  calculateResidualPercentage(minimumResidualPercentage: number, residualValue: number, vehiclePrice: number, claimableGST: number) {
    const residualPercent = (residualValue / (vehiclePrice - claimableGST)) * 100.0
    return Math.round(residualPercent * 100) / 100;
  }

  setFormsyValue(field: string, value: any) {
    if (this.props.formsy) {
      this.props.formsy.inputs.filter((input) => input.props.id == field)[0].setValue(value);
    }
  }

  getFormsyValue(field) {
    const matches = this.props.formsy && this.props.formsy.inputs.filter((input) => input.props.id == field);

    if (matches && matches.length) {
      return matches[0].getValue();
    }
    return undefined;
  }

  render() {
    return (
      <div>
            <Row>
              <Col sm={3}>
                  <Input
                    name="motorProduct.verificationLevelId"
                    type="hidden"
                  />

                  <Select
                    name="motorProduct.verificationLevelId"
                    label="Verification Level"
                    placeholder="Please select"
                    disabled
                    options={this.props.reference.verificationLevels}
                    className="form-control"
                  />
                </Col>

                <Col sm={3}>
                  <Input
                    name="motorProduct.productGroupId"
                    type="hidden"
                  />

                  <Select
                    name="motorProduct.productGroupId"
                    label="Loan Purpose"
                    placeholder="Please select"
                    disabled
                    options={this.props.reference.loanPurposes}
                    className="form-control"
                  />
                </Col> 
                <Col sm={3}>
                  <div className="form-suffix">%</div>
                  <Input
                    name="brokeragePercentageName"
                    id="brokeragePercentageId"
                    ref={this.brokeragePercentageRef}
                    onKeyUp={this.setBrokeragePercentage}
                    value={this.state.brokeragePercentage}
                    updateOnChange={false}
                    updateOnBlur={false}
                    blurCallback={(name, value) => { Mask.numberFormsy(name, value, this.refs, { decimal: 2 }); }}
                    onFocus={(event) => Mask.cleanFormsy(event.target.name, event.target.value, this.refs)}
                    label={(
                        <span>
                        {'Brokerage % '}
                        <small className="mute">
                          {`up to ${MAXIMUM_MOTOR_NOVATED_BROKERAGE_PERCENTAGE * 100}%`}
                        </small>
                        </span>
                        )}
                  />
                  <Input
                    name="brokeragePercentageValidation"
                    id="brokeragePercentageValidation"
                    ref="brokeragePercentageValidation"
                    value={this.state.brokeragePercentageValidation}
                    validationError={`The brokerage must be equal to or less than ${MAXIMUM_MOTOR_NOVATED_BROKERAGE_PERCENTAGE * 100}% of the total asset price`}
                    validations={{
                      isRequired: true,
                      isLesserAmount: (_, value) => parseFloat(value) <= MAXIMUM_MOTOR_NOVATED_BROKERAGE_PERCENTAGE * 100,
                    }}
                    style={{ display: 'none' }}
                  />
              </Col>
              <Col sm={3}>
                  <div className="form-prefix">$</div>
                  <Input
                  name="motorPurpose.applicationFee"
                  id="motorPurpose.applicationFee"
                  ref="motorPurpose.applicationFee"
                  label="Brokerage"
                  disabled
                  />
              </Col>
            </Row>

            <Row>
              <Col sm={3}>
                <Select
                  name="motorProduct.loanTermMonths"
                  id="motorProduct.loanTermMonths"
                  ref="motorProduct.loanTermMonths"
                  label="Choose a lease term"
                  placeholder="Please select"
                  options={[2, 3, 4, 5].map((year) => ({
                    label: `${year} years`,
                    value: year * 12,
                  }))}
                  className="form-control"
                  changeCallback={(field, value) => { this.setMinimumResidualPercentage(value); }}
                />
              </Col>
              <Col sm={3}>
                  <div className="form-prefix">$</div>

                  <Input
                  name="motorPurpose.vehiclePrice"
                  id="motorPurpose.vehiclePrice"
                  ref="motorPurpose.vehiclePrice"
                  onKeyUp={this.setVehiclePrice}
                  label={(
                  <span>
                    {'Vehicle Price '}
                  <small className="mute">(Inc. GST)</small>
                  </span>
                  )}
                  validationError="Vehicle price is required and can not be 0"
                  validations={{
                      isRequired: true,
                      isNotZero: true,
                  }}
                  />
              </Col> 
              <Col sm={3}>
                    <div className="form-prefix">$</div>
                    <Input
                    name="motorPurpose.accessories"
                    id="motorPurpose.accessories"
                    ref="motorPurpose.accessories"
                    onKeyUp={this.setAccessories}
                    label={(
                    <span>Accessories</span>
                    )}                    
                    />
                </Col>
                <Col sm={3}>
                    <div className="form-prefix">$</div>

                    <Input
                    name="motorPurpose.onRoadCost"
                    id="motorPurpose.onRoadCost"
                    ref="motorPurpose.onRoadCost"
                    onKeyUp={this.setOnRoadCosts}
                    label="On Road Cost"                    
                    />
                </Col>
            </Row>

            <Row>              
              
              <Col sm={3}>
                  <div className="form-prefix">$</div>

                  <Input
                  name="motorPurpose.luxuryCarTax"
                  id="motorPurpose.luxuryCarTax"
                  ref="motorPurpose.luxuryCarTax"
                  onKeyUp={this.setLuxuryTax}
                  label="Luxury Car Tax"                  
                  />
              </Col>
              <Col sm={3}>
                  <Input
                  name="motorPurpose.annualKilometres"
                  id="motorPurpose.annualKilometres"
                  ref="motorPurpose.annualKilometres"
                  label={(
                  <span>Annual Kilometres</span>
                  )} 
                  />
              </Col>
              <Col sm={3}>
                  <div className="form-prefix">$</div>
                  <Input
                  disabled
                  name="motorPurpose.claimableGST"
                  id="motorPurpose.claimableGST"
                  ref="motorPurpose.claimableGST"
                  label={(
                      <span>
                      {'Claimable Gst '}
                      <small className="mute">                      
                          up to $
                          {this.state.maxClaimableGstAmount}                    
                      </small>
                      </span>
                      )}
                  validationError={`Claimable Gst must be less than ${this.state.maxClaimableGstAmount}`}
                  validations={{
                      isLesserAmount: (values, value) => parseFloat(Mask.clean(value)) <= this.state.maxClaimableGstAmount || value == '0',
                  }}
                  />
                  <i className="mute mi mi-help-outline a pointer" style={{ right: '12px', top: '6px' }} data-event="click" data-tip="Claimable GST is the MIN of  ((Vehicle price + Accessories) x 1/11) OR $6191" />
              </Col>
              <Col sm={3}>
                  <div className="form-prefix">$</div>
                  <Input
                  disabled
                  name="motorPurpose.nonClaimableGST"
                  id="motorPurpose.nonClaimableGST"
                  ref="motorPurpose.nonClaimableGST"
                  label={(
                      <span>
                      {'Non-Claimable Gst '}
                      </span>
                      )}
                  />
              </Col>
            </Row>
 
            <Row>
              <Col sm={3}>
                  <div className="form-suffix">%</div>
                  <Input
                  name="residualPercentageName"
                  id="residualPercentageId"
                  ref={this.residualPercentageRef}
                  updateOnChange={false}
                  updateOnBlur={false}
                  blurCallback={(name, value) => { Mask.numberFormsy(name, value, this.refs, { decimal: 2 }); }}
                  onFocus={(event) => Mask.cleanFormsy(event.target.name, event.target.value, this.refs)}
                  onKeyUp={this.setResidualPercentage}
                  value={this.state.residualPercentage}
                  label={(
                      <span>
                      {'Residual % '}
                      <small className="mute">
                        {`at least ${this.state.minimumResidualPercentage}%`}
                      </small>
                      </span>
                      )}
                  />
                  <Input
                    name="residualPercentageValidation"
                    id="residualPercentageValidation"
                    ref="residualPercentageValidation"
                    value={this.state.residualPercentageValidation}
                    validationError={`The residual value must be at least ${this.state.minimumResidualPercentage}%`}
                    validations={{
                      isRequired: true,
                      isGreaterAmount: (values, value) => parseFloat(value) >= this.state.minimumResidualPercentage,   
                    }}
                    style={{ display: 'none' }}
                  />
              </Col>
              <Col sm={3}>
                  <div className="form-prefix">$</div>
                  <Input
                  name="motorPurpose.residualValue"
                  id="motorPurpose.residualValue"
                  ref="motorPurpose.residualValue"
                  onKeyUp={this.setResidualValue}
                  label="Residual Value"
                  validationError="Residual Value is required"
                  validations={{
                      isRequired: true,
                  }}
                  />
              </Col>
            </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    reference: state.reference,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...referenceActions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationUpdateProductNovatedLease);
