import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { hashHistory } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Overlay } from '../../utils';
import * as introducersActions from '../../actions/introducersActions';
import IntroducerItem from './IntroducerItem';

import Storage from '../../helpers/storage';

class IntroducerScroller extends React.Component {
  constructor(props) {
    super();

    this.props = props;
    this.state = {
      originators: [],
      page: 1,
      maxItems: 0,
      hasItems: true,
      search: '',
      hasLoadedOnce: false,
    };

    this.getIntroducers = this.getIntroducers.bind(this);
  }

  componentDidMount() {
    this.fetchMoreData(false);
  }

  fetchMoreData = (resetPage = false) => {
    this.getIntroducers(this.props.name, this.state.search, this.state.page, 15, resetPage);
  };

  handleSearchInputChange = (e) => {
    this.setState({ search: e.target.value });
  }

  handleSearchButtonClick = (e) => {
    this.fetchMoreData(true);
  }

  getIntroducers(email, search, page, pageCount, resetPage) {
    if (email === undefined || page === undefined) return;

    this.props.actions.getIntroducers(email, search, page, pageCount)
      .then((response) => {
        if (response === undefined) return;

        const data = response.data.data || [];
        const newOriginatorSet = data.originators || [];

        this.setState({ originators: resetPage ? newOriginatorSet : this.state.originators.concat(newOriginatorSet) });
        this.setState({ page: resetPage ? 1 : this.state.page + 1 });
        this.setState({ maxItems: data.totalOriginatorsCount });

        this.setState({ hasItems: (this.state.originators.length >= this.state.maxItems) });

        // If we only get one result back in total, then we can auto-select it as our originator
        if (this.state.originators.length === 1) {
          Storage.setItem('introducerIdHasChanged', true);
          Storage.setItem('session', this.state.originators[0].originatorId);
          Storage.setItem('username', this.state.originators[0].username);
          Storage.setItem('capabilities', this.state.originators[0].capabilities);

          let location = 'applications';
          if (Storage.getItem('redirect')) {
            location = Storage.getItem('redirect');
            Storage.removeItem('redirect');
          }
          hashHistory.push(location);
        }
        this.setState({ hasLoadedOnce: true });
      });
    setTimeout(this.getIntroducers, 1500);
  }

  render() {
    const safeArray = this.state.originators.filter((e) => e !== undefined);

    if (!this.state.hasLoadedOnce) {
      return (
        <Overlay>Signing in...</Overlay>
      );
    }

    return (
      <div className="form-group r mb-h">
        <div className="mt-lg pt-sm mb-lg text-center">
          <h1 className="mt-lg">
Select which account to use for
            {' '}
            {this.props.name}
          </h1>
          <h4 className="mute">
showing
            {' '}
            {this.state.originators.length}
            {' '}
of
            {' '}
            {this.state.maxItems}
            {' '}
accounts
          </h4>
        </div>
        <div style={{ padding: '12px 0px 12px 0px' }}>
          <Row>
            <Col md={11} lg={11} sm={11} xl={11} xs={11} xxl={11}>
              <input name="search" style={{ paddingLeft: '30px' }} className="animate-fast form-control form-control-search" placeholder="Search brokers" onChange={this.handleSearchInputChange} />
            </Col>
            <Col md={1} lg={1} sm={1} xl={1} xs={1} xxl={1}>
              <button name="searchButton" onClick={this.handleSearchButtonClick}>Search</button>
            </Col>
          </Row>
        </div>
        <div className="panel panel-default">
          <div className='panel-body p-h loan-list'>
            <InfiniteScroll
              dataLength={this.state.originators.length}
              next={this.fetchMoreData}
              hasMore={this.state.hasItems}
              loader={<h3>Loading...</h3>}
              scrollableTarget="scrollableDiv"
            >
              {
                safeArray.map((i, index) => (
                  <IntroducerItem key={index} originatorId={i.originatorId} username={i.username} fullname={i.fullname} capabilities={i.capabilities} />
                ))
              }
            </InfiniteScroll>
          </div>
        </div>
      </div>
    );
  }
}

IntroducerScroller.propTypes = {
  name: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    originators: state.originators,
    actions: state.actions,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...introducersActions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IntroducerScroller);
